// // import { useState, useEffect } from 'react';
// // import { CheckIcon } from '@heroicons/react/20/solid';

// // export default function PricingExample() {
// //   const [pricingPlans, setPricingPlans] = useState([]);

// //   useEffect(() => {
// //     fetch('http://192.168.1.118:8090/plan/ListSoftwarePlans', {
// //       method: 'POST',
// //       headers: {
// //         'Content-Type': 'application/json',
// //       },
// //       body: JSON.stringify({ SoftwareID: 14 }),
// //     })
// //       .then((response) => response.json())
// //       .then((data) => {
// //         setPricingPlans(data.data); // Set the pricing plans with the data array from the response
// //       })
// //       .catch((error) => console.error('Error fetching pricing plans:', error));
// //   }, []);

// //   return (
// //     <div className="bg-white py-68 sm:py-32">
// //       <div className="mx-auto max-w-7xl px-6 lg:px-8">
// //         <div className="mx-auto max-w-2xl sm:text-center">
// //           <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Pricing plans</h2>
// //         </div>
// //         <div className="mx-auto  max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
// //           {pricingPlans.map((plan, index) => (
// //             <div key={index} className="p-8 sm:p-10 lg:flex-auto">
// //               <h3 className="text-2xl font-bold tracking-tight text-gray-900">{plan.Name}</h3>
// //               <p className="mt-6 text-base leading-7 text-gray-600">{plan.Description}</p>
// //               <div className="mt-10 flex items-center gap-x-4">
// //                 <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
// //                 <div className="h-px flex-auto bg-gray-100" />
// //               </div>
// //               <ul
// //                 role="list"
// //                 className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
// //               >
// //                 {plan.SubPlans.Features.map((feature, featureIndex) => (
// //                   <li key={featureIndex} className="flex gap-x-3">
// //                     <CheckIcon aria-hidden="true" className="h-6 w-5 flex-none text-indigo-600" />
// //                     {feature.FeatureName}
// //                   </li>
// //                 ))}
// //               </ul>
// //               <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
// //                 <div className="rounded-2xl bg-gray-50 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
// //                   <div className="mx-auto max-w-xs px-8">
// //                     <p className="text-base font-semibold text-gray-600">
// //                       {plan.SubPlans.Duration} days plan
// //                     </p>
// //                     <p className="mt-6 flex items-baseline justify-center gap-x-2">
// //                       <span className="text-5xl font-bold tracking-tight text-gray-900">
// //                       ₹{plan.SubPlans.Amount}
// //                       </span>
// //                       <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600"></span>
// //                     </p>
// //                     <a
// //                       href="#"
// //                       className="mt-10 block w-full rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
// //                     >
// //                       Get access
// //                     </a>
// //                     <p className="mt-6 text-xs leading-5 text-gray-600">
// //                       Invoices and receipts available for easy company reimbursement
// //                     </p>
// //                   </div>
// //                 </div>
// //               </div>
// //             </div>
// //           ))}
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// import { useState, useEffect } from 'react';
// import { CheckIcon } from '@heroicons/react/20/solid';

// export default function PricingExample() {
//   const [pricingPlans, setPricingPlans] = useState([]);

//   useEffect(() => {
//     fetch('http://192.168.1.118:8090/plan/ListSoftwarePlans', {
//       method: 'POST',
//       headers: {
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({ SoftwareID: 14 }),
//     })
//       .then((response) => response.json())
//       .then((data) => {
//         setPricingPlans(data.data); // Set the pricing plans with the data array from the response
//       })
//       .catch((error) => console.error('Error fetching pricing plans:', error));
//   }, []);

//   return (
//     <div className="bg-[#F8F9FB] py-12 sm:py-16 lg:py-24">
//       <div className="mx-auto max-w-7xl px-6 lg:px-8">
//         <div className="mx-auto max-w-2xl sm:text-center">
//           <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
//             Pricing Plans
//           </h2>
//         </div>
//         <div className="mt-12 flex flex-col space-y-10 sm:mt-16 sm:flex-row sm:space-y-0 sm:space-x-8 lg:space-x-10">
//           {pricingPlans.map((plan, index) => (
//             <div
//               key={index}
//               className="bg-white p-8 rounded-3xl shadow-lg flex flex-col justify-between lg:flex-1 lg:space-y-6"
//             >
//               <div>
//                 <h3 className="text-2xl font-bold tracking-tight text-gray-900">
//                   {plan.Name}
//                 </h3>
//                 <p className="mt-4 text-base leading-7 text-gray-600">
//                   {plan.Description}
//                 </p>
//                 <div className="mt-8">
//                   <h4 className="text-sm font-semibold leading-6 text-[#C12581]">
//                     What’s included
//                   </h4>
//                   <ul className="mt-4 space-y-4">
//                     {plan.SubPlans.Features.map((feature, featureIndex) => (
//                       <li key={featureIndex} className="flex items-start gap-x-3">
//                         <CheckIcon aria-hidden="true" className="h-5 w-5 text-[#782C8F]" />
//                         <span className="text-sm text-gray-600">{feature.FeatureName}</span>
//                       </li>
//                     ))}
//                   </ul>
//                 </div>
//               </div>
//               <div className="mt-8 lg:mt-0">
//                 <div className="rounded-2xl bg-gray-50 py-8 text-center ring-1 ring-gray-200">
//                   <p className="text-base font-semibold text-gray-600">
//                     {plan.SubPlans.Duration} days plan
//                   </p>
//                   <p className="mt-4 flex items-baseline justify-center gap-x-2">
//                     <span className="text-4xl font-bold tracking-tight text-gray-900">
//                       ₹{plan.SubPlans.Amount}
//                     </span>
//                   </p>
//                   <a
//                     href="#"
//                     className="mt-8 block w-full rounded-md bg-[#782C8F] px-4 py-2 text-sm font-semibold text-white hover:bg-[#C12581] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#782C8F]"
//                   >
//                     Get access
//                   </a>
//                   <p className="mt-4 text-xs text-gray-600">
//                     Invoices and receipts available for easy company reimbursement
//                   </p>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }
import { useState, useEffect } from "react";
import { CheckIcon } from "@heroicons/react/20/solid";
import { HashLink } from "react-router-hash-link";

export default function PricingExample() {
  const [pricingPlans, setPricingPlans] = useState([
    {
        "_id": "66b1c1cb842bd9d4a0eeb2b0",
        "Name": "Trail Plan",
        "Description": "Trail Plan for Easy Tracker",
        "Softwares": {
            "SoftwareID": "66b1baf84cee5268483530d0",
            "Version": "1"
        },
        "SubPlans": {
            "Duration": 30,
            "Amount": 0,
            "Description": "Trail Plan for Easy Tracker",
            "Image": "/images/plan/Image_20240827131952.png",
            "Name": "Trail Plan",
            "id": 1,
            "Features": [
                {
                    "_id": "659d15c4b3f808a707ad5d43",
                    "Count": 5,
                    "FeatureName": "Salesman Creation",
                    "FeatureKey": "salesmancreation",
                    "status": true
                }
            ]
        },
        "ExpiryDate": "2031-12-31T00:00:00.000Z",
        "IsPlan": true,
        "SoftwareName": "EasyTracker"
    },
    {
        "_id": "66bd8a59a588ab67daeff01f",
        "Name": "Basic Plan",
        "Description": "Basic Plan for Easy Tracker",
        "Softwares": {
            "SoftwareID": "66b1baf84cee5268483530d0",
            "Version": null
        },
        "SubPlans": {
            "Duration": 365,
            "Amount": 4999,
            "Description": "Basic Plan for Easy Tracker",
            "Image": "",
            "Name": "Basic Plan",
            "id": 1,
            "Features": [
                {
                    "_id": "659d15c4b3f808a707ad5d43",
                    "Count": 5,
                    "FeatureName": "Salesman Creation",
                    "FeatureKey": "salesmancreation",
                    "status": true
                }
            ]
        },
        "ExpiryDate": "2031-12-31T00:00:00.000Z",
        "IsPlan": true,
        "SoftwareName": "EasyTracker"
    }
]);

  // useEffect(() => {
  //   fetch("http://192.168.1.118:8090/plan/ListSoftwarePlans", {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ SoftwareID: 14 }),
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {

  //       console.log(data.data)
  //       setPricingPlans(data.data); // Set the pricing plans with the data array from the response
  //     })
  //     .catch((error) => console.error("Error fetching pricing plans:", error));
  // }, []);

  return (
    <div className="bg-[#F8F9FB] py-12 sm:py-16 lg:py-24" id="pricing">
      <div className="mx-auto max-w-3xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-3xl text-center  text-secondary font-bold tracking-tight sm:text-4xl">
            Plans and Pricings{" "}
          </h2>
        </div>
        <div className="mt-12 flex  flex-col space-y-10 sm:mt-16 sm:flex-row sm:space-y-0 sm:space-x-8 lg:space-x-10">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className="bg-white border-2 border-red-200 p-8 rounded-3xl shadow-lg flex flex-col justify-between lg:flex-1 lg:space-y-6"
            >
              <div className=" ">
                <h3 className="text-2xl text-center font-bold tracking-tight text-gray-900">
                  {plan.Name}
                </h3>
                <p className="mt-4  text-center text-base leading-7 text-gray-600">
                  {plan.Description}
                </p>
                <div className="mt-8">
                  <h4 className="text-sm text-center font-semibold leading-6 text-[#C12581]">
                    What’s included
                  </h4>
                  <ul className="mt-4 space-y-4">
                    {plan.SubPlans.Features.map((feature, featureIndex) => (
                      <li
                        key={featureIndex}
                        className="flex items-start gap-x-3"
                      >
                        <CheckIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-[#782C8F]"
                        />
                        <span className="text-sm text-gray-600">
                          {feature.FeatureName}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="mt-8 lg:mt-0">
                <div className="rounded-2xl bg-gray-50 py-8 text-center ring-1 ring-gray-200">
                  <p className="text-base font-semibold text-gray-600">
                    {plan.SubPlans.Duration} days plan
                  </p>
                  <p className="mt-4 flex items-baseline justify-center gap-x-2">
                    <span className="text-4xl font-bold tracking-tight text-gray-900">
                      ₹{plan.SubPlans.Amount}
                    </span>
                  </p>
                  {/* <a
                    href="./#contact"
                    className="mt-8 block  w-36 mx-auto rounded-md bg-[#782C8F] px-4 py-2 text-sm font-semibold text-white hover:bg-[#C12581] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#782C8F]"
                  > */}
                    {/* Get access
                  </a> */}

<HashLink
             className="mt-8 block  w-36 mx-auto rounded-md bg-[#782C8F] px-4 py-2 text-sm font-semibold text-white hover:bg-[#C12581] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#782C8F]"
          
            to="./#contact"
          >
             Get access
            </HashLink>
                  <p className="mt-4 text-xs text-gray-600">
                    Invoices and receipts available for easy company
                    reimbursement
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
