import React, { useState, useEffect } from "react";
import logo from "../assets/logo.png";
import ezui1 from "../assets/goodpic.png";

const About = () => {
  const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);

  const features = [
    {
      name: "Add Field Executives/Salesmen",
      img: ezui1,
      benefits: [
        "Effortlessly organize and manage your field team to boost efficiency.",
        "Save time and minimize errors when tracking and assigning tasks.",
        "Enhance team accountability and elevate overall performanc",
      ],
    },
    {
      name: "Assign Customer Visits",
      img: ezui1,
      benefits: [
        "Guarantee prompt and well-coordinated interactions with customers.",
        "Boost customer satisfaction by consistently meeting service commitments.",
        "Optimize scheduling to maximize productivity.",
      ],
    },
    {
      name: "Live Tracking",
      img: ezui1,
      benefits: [
        "Acquire real-time visibility into your team's locations and activities",
        "Enhance response times to unexpected changes and delays",
        "Boost operational efficiency and control for better management.",
      ],
    },
    {
      name: "Tracking Summary",
      img: ezui1,
      benefits: [
        "Obtain comprehensive performance insights for every team member.",
        "Pinpoint areas for improvement and optimize overall productivity",
        "Utilize data-driven insights to enhance operational effectiveness.",
      ],
    },
    {
      name: "User Reports",
      img: ezui1,
      benefits: [
        "Access detailed reports to facilitate informed decision-making.",
        "Effectively evaluate team performance and productivity.",
        "Foster continuous improvement in operational processes.",
      ],
    },
    {
      name: "Attendance Reports",
      img: ezui1,
      benefits: [
        "Track attendance to ensure team reliability and accountability",
        "Quickly identify and address any attendance issues.",
        "Enhance workforce management strategies for better outcomes.",
      ],
    },
    {
      name: "Analysis Reports",
      img: ezui1,
      benefits: [
        "Gain valuable insights into performance metrics and trends.",
        "Make data-driven decisions to improve operational efficiency.",
        "Support strategic planning and foster ongoing improvement",
      ],
    },
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setSelectedFeatureIndex((prevIndex) => (prevIndex + 1) % features.length);
    }, 5000); // Change feature every 5 seconds

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [features.length]);
  return (
    <div
      className="w-full text-white flex items-center justify-center flex-col h-full px-10 max-sm:px-4 scroll-mt-[80px] min-h-[90vh]"
      id="about"
    >
      <div className="h-full rounded-md px-10 py-8 flex flex-col items-center gap-10 text-center max-sm:px-2">
        <span className="text-4xl font-bold text-primary">Our Product</span>
        <div className="flex gap-10 max-sm:flex-col-reverse max-sm:items-center items-start">
          <p className="text-gray-800 text-justify text-lg w-[90%] max-sm:w-[100%]">
            <span className="text-secondary font-semibold text-2xl">
              Easy Tracker {" "}
            </span>
            is the ultimate tool for managing and monitoring your sales team
            with ease. With the ability to add users and efficiently assign
            customer visits, you can ensure your salesmen are always on track.
            The app provides live tracking capabilities, so you can monitor
            their real-time locations and activities. Keep your business
            organized with detailed tracking summaries and attendance reports,
            ensuring every task is accounted for. The powerful analysis reports
            feature helps you make informed decisions by providing insights into
            your team's performance. Easy Tracker simplifies workforce
            management, boosting productivity and enhancing oversight.
          </p>

          <img
            src={logo}
            alt="logo"
            className="w-[200px] h-[200px] aspect-square"
          />
        </div>

        <div className="flex items-center flex-col gap-10">
          <p className="text-3xl font-semibold text-secondary">
            Discover Our Powerful Features
          </p>
          <ul className="grid  rounded-2xl grid-cols-4 max-lg:grid-cols-2 max-sm:grid-cols-1 justify-center gap-2 text-primary items-center    ">
            {features.map((feature, idx) => {
              const isSelected = idx === selectedFeatureIndex;
              return (
                <li
                  className={`text-center border border-red-500 px-4 p-3 max-sm:basis-[256px] max-lg:rounded-xl max-sm:rounded-lg rounded-md cursor-pointer font-medium hover:bg-secondary hover:text-white transition ease-in-out duration-200 ${
                    isSelected && "bg-primary text-white"
                  }`}
                  onClick={() => setSelectedFeatureIndex(idx)}
                  key={idx}
                >
                  {feature.name}
                </li>
              );
            })}
          </ul>
        </div>
        <div className="flex items-center max-sm:flex-col gap-4 max-sm:gap-2 lg:w-[70%] sm:mt-4">
          <div className="w-2/4 hidden md:flex lg:flex max-sm:w-full justify-center items-center">
            <div className="p-0 w-full flex justify-center items-center">
              <img
                src={features[selectedFeatureIndex].img}
                alt={features[selectedFeatureIndex].name}
                className="w-full max-w-40 rounded-lg"
              />
            </div>
          </div>

          <div className="text-gray-800 text-lg flex flex-col gap-2 w-2/4 max-sm:w-full">
            <span className="text-secondary font-semibold">
              {features[selectedFeatureIndex].name}
            </span>
            <ul className="list-disc text-primary rounded-xl pl-5 text-left w-full h-90 overflow-auto">
              {features[selectedFeatureIndex].benefits.map((benefit, idx) => (
                <li key={idx} className="flex mt-4 items-center">
                  <svg
                    className="w-6 h-6 text-green-400 mr-2 flex-shrink-0"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
