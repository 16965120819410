import React from "react";
import { BsArrowRight } from "react-icons/bs";
import backgroundImage from "../assets/8293039.jpg"; // Adjust the path as necessary

const Home = () => {
  return (
    <div
      className="w-full min-h-[95vh]   flex px-10 p-4 h-full flex items-center justify-center flex-col gap-10 scroll-mt-[100px]"
      id="home"
    >
      <h3 className="text-slate-700 p-4 bg-white rounded-xl text-6xl text-center font-semibold max-sm:text-3xl">
        Field Management Simplified
      </h3>
      <a href="#contact">
        <button className="bg-secondary text-white p-4 rounded-lg text-lg flex gap-2 transition ease-in-out duration-200 font-medium hover:bg-background hover:text-secondary items-center">
          Request a quote <BsArrowRight className="w-5 h-5" />
        </button>
      </a>
    </div>
  );
};

export default Home;
