import React from "react";
import Home from "../components/Home";
import DownloadApp from "../components/DownloadApp";
import ContactUs from "../components/ContactUs";
// import Demo from "./Demo";
import About from "../components/About";
import PricingExample from "../components/PricingExample";
import MoreApps from "../components/MoreApps";
// import PricingMain from "../components/PricingMain";
const HomePage = () => {
  return (
    <div className="flex  flex-col gap-5">
      <Home />
      <About />
      <DownloadApp />
      {/* <PricingMain/> */}
      <PricingExample />
      <ContactUs />
      <MoreApps />
      {/* <Demo/> */}
    </div>
  );
};

export default HomePage;
