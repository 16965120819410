import React, { useEffect, useState } from "react";
import logo from "../assets/logo.png";
import { RiCloseFill, RiMenu3Line } from "react-icons/ri";

import { HashLink } from "react-router-hash-link";
const Navbar = () => {
  const [isSmallDevice, setIsSmallDevice] = useState(
    window.innerWidth < 790 ? true : false
  );
  const [isMenuOpen, setIsMenuOpen] = useState(!isSmallDevice);

  const handleResize = () => {
    if (window.innerWidth < 780) {
      setIsSmallDevice(true);
      setIsMenuOpen(false);
    } else {
      setIsSmallDevice(false);
      setIsMenuOpen(true);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuClick = () => {
    if (isSmallDevice) {
      setIsMenuOpen(false);
    }
  };

  return (
    <nav className="bg-white border-gray-700 min-h-[50px] px-10 max-md:px-4 p-2 flex items-center justify-between max-md:flex-col sticky top-0 left-0 z-20">
      <div
        className={`flex gap-4 items-center ${
          isSmallDevice && "w-full"
        } justify-between`}
      >

<a href="./">
        <img href="./" src={logo} alt="Logo" className="w-14 h-14" />
</a>
        <div className=" md:flex flex-col">
          <a href="./" className="text-2xl font-bold text-secondary ">
            Easy Tracker
          </a>
          <p className="text-xs text-navfieldpurp font-semibold text-blue-shade2">
            Field Management Simplified
          </p>
        </div>

        {isSmallDevice &&
          (isMenuOpen ? (
            <RiCloseFill
              className="w-8 h-8 text-secondary "
              onClick={() => setIsMenuOpen(false)}
            />
          ) : (
            <RiMenu3Line
              className="w-8 h-8 text-secondary "
              onClick={() => setIsMenuOpen(true)}
            />
          ))}
      </div>

      {isMenuOpen && (
        <ul
          className={`flex gap-5 text-black text-md items-center max-md:flex-col transition ease-in-out duration-[.5s] ${
            isSmallDevice && isMenuOpen
              ? "absolute top-[90%] w-full z-20 py-10 text-lg left-0 bg-white"
              : ""
          }`}
        >
           <HashLink
          onClick={handleMenuClick}
            to="./#home"
          >
            Home
          </HashLink>
          <li className="cursor-pointer hover:underline whitespace-nowrap">
          <HashLink
          onClick={handleMenuClick}
            to="/#about"
          >
           Product
          </HashLink>
          </li>
          <li className="cursor-pointer hover:underline whitespace-nowrap">
          <HashLink
          onClick={handleMenuClick}
            to="/#pricing"
          >
         Pricing
          </HashLink>
          </li>
          <HashLink to="/demo">App FAQ</HashLink>
          <li className="cursor-pointer hover:underline bg-secondary text-background font-medium rounded-xl px-4 p-1 whitespace-nowrap">
            {/* <ha href="./#contact" onClick={handleMenuClick}>
              Request demo
            </a> */}
              <HashLink
          onClick={handleMenuClick}
            to="./#contact"
          >
            Request Demo
          </HashLink>
          </li>
        </ul>
      )}
    </nav>
  );
};

export default Navbar;
